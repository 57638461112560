/* eslint-disable @typescript-eslint/no-shadow */
import { useDisclosure, useToast } from "@chakra-ui/react";
import { createContext, useEffect, useState } from "react";
import { HighTicketAdminProviderProps } from "../types";
import { GetHighTicketsForAdminPanelDocument } from "../graphql/getHighTicketsForAdminPanel.generated";
import { noCacheHeaders } from "@/utils/headers";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { CreateHighTicketMentorshipDocument } from "../graphql/createHighTicketMentorship.generated";
import {
  CreateHighTicketMentorshipInput,
  UpdateHighTicketMentorshipInput,
  UserRole,
} from "@/schemaTypes";
import { UpdateHighTicketMentorshipDocument } from "../graphql/updateHighTicketMentorship.generated";
import { SearchUserDocument } from "@/components/Users/graphql/searchUser.generated";

export const HighTicketAdminContext =
  createContext<HighTicketAdminProviderProps | null>(null);

export const HighTicketAdminprovider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    isOpen: IsVIPmentorshipModalOpen,
    onOpen: openVIPmentorshipModal,
    onClose: closeVIPmentorshipModal,
  } = useDisclosure();

  const {
    isOpen: isMentorshipModalOpen,
    onOpen: openCreateMentorshipModal,
    onClose: closeCreateMentorshipModal,
  } = useDisclosure();

  const [adminMentorshipQuery, { data: mentorshipsForPanel, loading, error }] =
    useLazyQuery(GetHighTicketsForAdminPanelDocument, {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    });
  useEffect(() => {
    adminMentorshipQuery();
  }, [adminMentorshipQuery]);

  const [selectedMentorshipId, setSelectedMentorshipId] = useState<string>("");
  const [selectedMentorship, setSelectedMentorship] =
    useState<UpdateHighTicketMentorshipInput | null>(null);
  useEffect(() => {
    if (!selectedMentorshipId) return setSelectedMentorship(null);

    if (selectedMentorshipId && mentorshipsForPanel) {
      const selectedMentorship =
        mentorshipsForPanel.getHighTicketsForAdminPanel?.find(
          (mentorship) => mentorship?.id === selectedMentorshipId
        );
      return setSelectedMentorship(
        selectedMentorship as UpdateHighTicketMentorshipInput
      );
    }
  }, [selectedMentorshipId]);

  const { data: mentorsAndTeams } = useQuery(SearchUserDocument, {
    variables: {
      userSearchInput: {
        roles: [UserRole.Mentor],
        entityName: "user",
        limit: 200,
      },
    },
  });

  const resetCreateServiceModal = () => {
    closeCreateMentorshipModal();
    closeVIPmentorshipModal();
    setSelectedMentorshipId("");
    setSelectedMentorship(null);
  };

  const handleOperationSuccess = (toastTitle: string) => {
    adminMentorshipQuery();
    resetCreateServiceModal();
    toast({
      title: t(toastTitle),
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  const [createHighTicketMentorship, { loading: isCreateLoading }] =
    useMutation(CreateHighTicketMentorshipDocument);

  const handleCreateHighTicketMentorship = (
    values: CreateHighTicketMentorshipInput | UpdateHighTicketMentorshipInput
  ) => {
    createHighTicketMentorship({
      variables: {
        createHighTicketMentorshipInput:
          values as CreateHighTicketMentorshipInput,
      },
    })
      .then((response) => {
        if (response.data?.createHighTicketMentorship)
          return handleOperationSuccess("Mentoria creada exitosamente");
      })
      .finally(resetCreateServiceModal);
  };

  const [updateHighTicketMentorship, { loading: isUpdateLoading }] =
    useMutation(UpdateHighTicketMentorshipDocument);

  const handleUpdateHighTicketMentorship = (
    values: UpdateHighTicketMentorshipInput | CreateHighTicketMentorshipInput
  ) => {
    updateHighTicketMentorship({
      variables: {
        updateHighTicketMentorshipInput: {
          ...(values as UpdateHighTicketMentorshipInput),
        },
      },
    })
      .then((response) => {
        if (response.data?.updateHighTicketMentorship)
          return handleOperationSuccess("Mentoria actualizada");
      })
      .finally(resetCreateServiceModal);
  };

  return (
    <HighTicketAdminContext.Provider
      value={{
        IsVIPmentorshipModalOpen,
        openVIPmentorshipModal,
        closeVIPmentorshipModal,
        isMentorshipModalOpen,
        openCreateMentorshipModal,
        closeCreateMentorshipModal,
        mentorshipsForPanel,
        mentorshipsForPanelLoading: loading,
        mentorshipsForPanelError: error,
        selectedMentorshipId,
        setSelectedMentorshipId,
        selectedMentorship,
        setSelectedMentorship,
        isCreateLoading,
        handleCreateHighTicketMentorship,
        isUpdateLoading,
        handleUpdateHighTicketMentorship,
        mentorsAndTeams,
        resetCreateMentorship: resetCreateServiceModal,
      }}
    >
      {children}
    </HighTicketAdminContext.Provider>
  );
};
