import { Ico } from "@/assets/icons";
import {
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  Button,
} from "@chakra-ui/react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { usePresenter } from "../presenter/usePresenter";

export const SearchBar: React.FC = () => {
  const {
    isMobile,
    searchTerm,
    handleSearchChange,
    handleSearchClick,
    handleCleanSearch,
  } = usePresenter();
  return (
    <Flex
      mb={"24px"}
      alignItems={"center"}
      justifyContent={"start"}
      display={isMobile ? "grid" : "flex"}
      gridTemplateColumns={isMobile ? "6fr 2fr" : "1fr"}
      w={"100%"}
      flexDirection={"row"}
      gap={isMobile ? "4px" : "auto"}
    >
      <InputGroup
        mr={isMobile ? 0 : 4}
        maxWidth={isMobile ? undefined : "512px"}
        h={"40px"}
      >
        <InputLeftElement pointerEvents="none">
          <Ico.MagnifyingGlass></Ico.MagnifyingGlass>
        </InputLeftElement>
        <Input
          fontSize={"14px"}
          _placeholder={"font-size: 14px"}
          placeholder={t("Buscar")}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </InputGroup>

      <Button
        maxWidth={isMobile ? undefined : "512px"}
        w={isMobile ? "100%" : "auto"}
        h={"40px"}
        fontSize={"14px"}
        variant={"primary"}
        onClick={
          searchTerm && searchTerm.length > 0
            ? handleSearchClick
            : handleCleanSearch
        }
      >
        <Trans>Buscar</Trans>
      </Button>
    </Flex>
  );
};
