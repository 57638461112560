import { FilterOptions } from "./filterTypes";
import { Button } from "../Button";
import { useContext, useEffect, useState } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { learnContext } from "@/pages/academy/Learn/providers/learnProvider";

type AcademicFilterProps = {
  filterOptions: string[] | null;
  handleFilterChange: (filter: string) => void;
};

const AcademicFilter = ({
  filterOptions,
  handleFilterChange,
}: AcademicFilterProps) => {
  const { selectedSubCategory } = useContext(learnContext);
  const path = useLocation().pathname;
  const [selected, setSelected] = useState<string>(FilterOptions[0].label);
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const handlePressFilter = (filter: string) => {
    setSelected(filter);
    handleFilterChange(filter);
  };

  useEffect(() => {
    if (filterOptions && filterOptions.length > 0) {
      setSelected(filterOptions[0]);
      handleFilterChange(filterOptions[0]);
    }
  }, [path]);

  useEffect(() => {
    setSelected("Todos");
    handleFilterChange("Todos");
  }, [selectedSubCategory]);

  return (
    filterOptions &&
    filterOptions.length > 2 && (
      <Box
        px={{
          base: "16px",
          lg: "0",
        }}
        mb={"16px"}
        overflow="hidden"
        display="flex"
        gap={"16px"}
        overflowX={isMobile ? "scroll" : "visible"}
        css={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          "-ms-overflow-style": "none",
          "scrollbar-width": "none",
        }}
      >
        {filterOptions.map((filter) => (
          <Button
            key={filter}
            px={"12px"}
            py={"8px"}
            paddingTop="7px"
            isSelected={selected === filter}
            onClick={() => handlePressFilter(filter)}
            variant="option"
            flexShrink={0}
            css={{
              borderStyle: "solid",
              borderWidth: selected === filter ? "2px" : "1px",
            }}
          >
            {filter}
          </Button>
        ))}
      </Box>
    )
  );
};

export default AcademicFilter;
