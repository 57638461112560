import { createContext, useContext, useEffect, useState } from "react";
import { HighTicketAdminContext } from "./HighTicketAdminProvider";
import { Form, Formik } from "formik";
import {
  CreateHighTicketMentorshipInput,
  EntityName,
  UpdateHighTicketMentorshipInput,
} from "@/schemaTypes";
import { useQuery } from "@apollo/client";
import { noCacheHeaders } from "@/utils/headers";
import { CategoriesDocument } from "../../Categories/graphql/getCategories.generated";
import { UserCardFragment } from "@/components/Users/graphql/userCardFragment.generated";
import {
  Faq,
  GroupSession,
  MentorshipFormValues,
  MentorshipModalContext,
  TeamMember,
} from "../types";
import { getInitialValues } from "../utils";
import { SearchUserQuery } from "@/components/Users/graphql/searchUser.generated";

const mentorshipModalContext = createContext<MentorshipModalContext | null>(
  null
);

const MentorshipModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const mentorshipAdminProvider = useContext(HighTicketAdminContext);

  if (!mentorshipAdminProvider) {
    throw new Error(
      "MentorshipModalProvider must be used within a HighTicketAdminProvider"
    );
  }

  const {
    selectedMentorship,
    selectedMentorshipId,
    mentorsAndTeams,
    handleCreateHighTicketMentorship,
    handleUpdateHighTicketMentorship,
  } = mentorshipAdminProvider;

  const { data: categories } = useQuery(CategoriesDocument, {
    fetchPolicy: "no-cache",
    context: { headers: noCacheHeaders },
  });

  const [mentorshipIdForDropdown, setMentorshipForDropdown] = useState<
    string | null
  >(null);
  const [parentIdForCopyValidation, setParentIdForCopyValidation] = useState<
    string | null | undefined
  >(null);

  const [mentorExperiences, setMentorExperiences] = useState([""]);
  const [faqs, setFaqs] = useState<Faq[]>([]);
  const [groupSessions, setGroupSessions] = useState<GroupSession[]>([]);
  const [workTeam, setWorkTeam] = useState<TeamMember[]>([]);
  const [oneOnOneBenefits, setOneOnOneBenefits] = useState([""]);

  const [filteredMentors, setFilteredMentors] = useState<
    UserCardFragment[] | null
  >();
  useEffect(() => {
    if (mentorsAndTeams && selectedMentorship) {
      const mentorsIds = (
        selectedMentorship as UpdateHighTicketMentorshipInput
      )?.workTeam?.flatMap((member) => member?.userId);

      const mentorsIdsFromForm = workTeam.flatMap((_) => _.userId);

      setFilteredMentors(
        (mentorsAndTeams as SearchUserQuery).searchUsers.filter(
          (mentor) =>
            mentorsIds?.includes(mentor.id) ||
            mentorsIdsFromForm?.includes(mentor.id) ||
            mentor.id === selectedMentorship?.mentor?.userId
        )
      );
    }
  }, [mentorsAndTeams, selectedMentorship, workTeam]);

  const dynamicFieldsHelper = (
    updatedSelection:
      | UpdateHighTicketMentorshipInput
      | CreateHighTicketMentorshipInput
  ) => {
    setMentorExperiences(
      updatedSelection?.mentor?.previousExperience &&
        updatedSelection?.mentor?.previousExperience.length > 0
        ? (updatedSelection?.mentor?.previousExperience as Array<string>)
        : [""]
    );
    setFaqs(
      updatedSelection?.faq && updatedSelection?.faq.length > 0
        ? (updatedSelection?.faq as Array<{
            question: string;
            answer: string;
          }>)
        : []
    );
    setOneOnOneBenefits(
      updatedSelection?.oneOnOneBenefits &&
        updatedSelection?.oneOnOneBenefits.length > 0
        ? (updatedSelection?.oneOnOneBenefits as Array<string>)
        : [""]
    );
    setGroupSessions(
      updatedSelection?.groupSessions &&
        updatedSelection?.groupSessions.length > 0
        ? (updatedSelection?.groupSessions as Array<{
            userId: string;
            link: string;
            date: Date;
            participants: Array<string>;
            topics?: Array<string>;
            title?: string;
          }>)
        : []
    );
    setWorkTeam(
      updatedSelection?.workTeam && updatedSelection.workTeam.length > 0
        ? (updatedSelection.workTeam as Array<{
            userId: string;
            description: string;
            talents: Array<{ title: string; content: string }>;
          }>)
        : []
    );
  };

  useEffect(() => {
    if (!selectedMentorship || !selectedMentorshipId) {
      setMentorExperiences([""]);
      setFaqs([]);
      setOneOnOneBenefits([""]);
      setGroupSessions([]);
      setWorkTeam([]);
    }
  }, [selectedMentorship, selectedMentorshipId]);

  const handleAddExperience = () => {
    setMentorExperiences([...mentorExperiences, ""]);
  };
  const handleAddOneOnOneBenefit = () => {
    setOneOnOneBenefits([...oneOnOneBenefits, ""]);
  };
  const handleAddFaq = () => {
    setFaqs([...faqs, { question: "", answer: "" }]);
  };
  const handleAddGroupSession = () => {
    setGroupSessions([
      ...groupSessions,
      { userId: "", link: "", date: new Date(), participants: [] },
    ]);
  };
  const handleAddWorkTeam = () => {
    setWorkTeam([...workTeam, { userId: "", description: "", talents: [] }]);
  };
  const handleRemoveExperience = (index: number) => {
    const newMentorExperiences = mentorExperiences.filter(
      (_, i) => i !== index
    );
    setMentorExperiences(newMentorExperiences);
  };
  const handleRemoveOneOnOneBenefit = (index: number) => {
    const newOneOnOneBenefits = oneOnOneBenefits.filter((_, i) => i !== index);
    setOneOnOneBenefits(newOneOnOneBenefits);
  };
  const handleRemoveGroupSession = (index: number) => {
    const newGroupSessions = groupSessions.filter((_, i) => i !== index);
    setGroupSessions(newGroupSessions);
  };
  const handleRemoveWorkTeam = (index: number) => {
    const newWorkTeam = workTeam.filter((_, i) => i !== index);
    setWorkTeam(newWorkTeam);
  };
  const handleGroupSessionsChange = (index: number, value: GroupSession) => {
    const newGroupSessions = [...groupSessions];
    newGroupSessions[index] = value;
    setGroupSessions(newGroupSessions);
  };
  const handleFaqChange = (index: number, value: Faq) => {
    const newFaqs = [...faqs];
    newFaqs[index] = value;
    setFaqs(newFaqs);
  };
  const handleWorkTeamChange = (index: number, value: TeamMember) => {
    const newWorkTeam = [...workTeam];
    newWorkTeam[index] = value;
    setWorkTeam(newWorkTeam);
  };
  const handleOneOnOneBenefitsChange = (index: number, value: string) => {
    const newOneOnOneBenefits = [...oneOnOneBenefits];
    newOneOnOneBenefits[index] = value;
    setOneOnOneBenefits(newOneOnOneBenefits);
  };
  const handleMentorExperienceChange = (index: number, value: string) => {
    const newMentorExperiences = [...mentorExperiences];
    newMentorExperiences[index] = value;
    setMentorExperiences(newMentorExperiences);
  };
  const handleRemoveFaq = (index: number) => {
    const newFaqs = faqs.filter((_, i) => i !== index);
    setFaqs(newFaqs);
  };

  useEffect(() => {
    if (selectedMentorship) {
      dynamicFieldsHelper(selectedMentorship);
      setMentorshipForDropdown(selectedMentorship.id);
      setParentIdForCopyValidation(selectedMentorship.parentId);
    }
  }, [selectedMentorship]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = getInitialValues(
    selectedMentorship,
    parentIdForCopyValidation ?? ""
  );

  const handleSubmit = (values: MentorshipFormValues) => {
    setIsSubmitting(true);

    let mentorshipToSave:
      | CreateHighTicketMentorshipInput
      | UpdateHighTicketMentorshipInput = {
      title: values.title,
      description: values.description,
      duration: values.duration,
      logo: values.logo,
      isPublished: true,
      mentor: {
        userId: values.mentorId ?? "",
        description: values.mentorDescription ?? "",
        title: values.mentorTitle ?? "",
        previousExperience: mentorExperiences,
        contactInfo: {
          email: values.email,
          phoneNumber: values.phoneNumber,
        },
        socialNetworks: {
          facebookLink: values.facebookLink,
          instagramLink: values.instagramLink,
          linkedInLink: values.linkedInLink,
        },
      },
      faq: faqs,
      categoryId: values.categoryId,
      qrCodeLink: values.qrCodeLink,
      whatsAppLink: values.whatsAppLink,
      oneOnOneBenefits: oneOnOneBenefits,
      groupSessions: (values as unknown as MentorshipFormValues)
        .groupSessions as GroupSession[],
      oneToOneSessions: values.oneToOneSessions,
      workTeam: workTeam,
      extraBenefits: {
        privateGroup: values.privateGroup ?? "",
        vipGroup: values.vipGroup ?? "",
        generalClasses: values.generalClasses ?? "",
        exclusiveSupport: values.exclusiveSupport ?? "",
        weHopeToTeachYou: values.weHopeToTeachYou ?? "",
      },
      type: (values.type as EntityName) ?? EntityName.VipMentorship,
      parentId: values.parentId,
      endDate: values.endDate,
      startDate: values.startDate,
    };

    if (selectedMentorshipId) {
      mentorshipToSave = {
        ...mentorshipToSave,
        id: selectedMentorshipId,
      };
    }

    if ("id" in mentorshipToSave && mentorshipToSave.id && selectedMentorship) {
      handleUpdateHighTicketMentorship(
        mentorshipToSave as UpdateHighTicketMentorshipInput
      );
    } else {
      handleCreateHighTicketMentorship(
        mentorshipToSave as CreateHighTicketMentorshipInput
      );
    }

    setIsSubmitting(false);
  };
  return (
    <mentorshipModalContext.Provider
      value={{
        categories,
        mentorshipIdForDropdown,
        setMentorshipForDropdown,
        parentIdForCopyValidation,
        setParentIdForCopyValidation,
        mentorExperiences,
        setMentorExperiences,
        faqs,
        setFaqs,
        groupSessions,
        setGroupSessions,
        workTeam,
        setWorkTeam,
        oneOnOneBenefits,
        setOneOnOneBenefits,
        filteredMentors,
        handleAddExperience,
        handleAddOneOnOneBenefit,
        handleAddFaq,
        handleAddGroupSession,
        handleAddWorkTeam,
        handleRemoveExperience,
        handleRemoveOneOnOneBenefit,
        handleRemoveGroupSession,
        handleRemoveWorkTeam,
        handleRemoveFaq,
        handleGroupSessionsChange,
        handleOneOnOneBenefitsChange,
        handleMentorExperienceChange,
        handleFaqChange,
        handleWorkTeamChange,
        dynamicFieldsHelper,
        isSubmitting,
      }}
    >
      <Formik
        initialValues={initialValues as MentorshipFormValues}
        onSubmit={(values: MentorshipFormValues) => handleSubmit(values)}
      >
        <Form>{children}</Form>
      </Formik>
    </mentorshipModalContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export { mentorshipModalContext, MentorshipModalProvider };
