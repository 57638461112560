import { Ico } from "@/assets/icons";
import { Flex, Heading, Button, Image, Text, Textarea } from "@chakra-ui/react";
import { usePresenter } from "../presenter/usePresenter";
import { useState, useContext } from "react";
import { GatewayLayerContext } from "../gatewayLayer/context";
import { useNotificationToast } from "../hooks/useNotificationToast";
import { colors } from "@/components/Theme/colors";

export const ContactMeWidget: React.FC = () => {
  const { currentServiceForView: currentService, isMobile } = usePresenter();
  const { showErrorToast } = useNotificationToast();
  const [projectDetails, setProjectDetails] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const context = useContext(GatewayLayerContext);

  if (!context) {
    throw new Error(
      "GatewayLayerContext no está disponible. ¿Olvidaste envolver tu componente con GatewayLayerProvider?"
    );
  }
  const { sendNotificationToAdmins } = context;

  const handleContactMeClick = async () => {
    const details = projectDetails.trim();
    const validRecipients = (currentService?.recipientsEmails ?? []).filter(
      (email): email is string => email !== null && email !== undefined
    );

    if (validRecipients.length === 0) {
      showErrorToast(
        "Error al contactar",
        "Hay un problema para contactar con la agencia. Por favor, comunícate con soporte."
      );
      return;
    }
    const sendData = await sendNotificationToAdmins({
      agencyName: currentService?.agency ?? "",
      userMessage:
        details === ""
          ? "¡Hola! Estoy interesado en sus servicios, me gustaría ponerme en contacto con ustedes"
          : details,
      recipients: validRecipients,
    });

    if (sendData) {
      setProjectDetails("");
      setIsSubmitted(true);
    } else {
      showErrorToast("Error", "No se pudo enviar la notificación.");
    }
  };

  return (
    <Flex
      px={"24px"}
      py={"16px"}
      h={"fit-content"}
      position={isMobile ? "fixed" : undefined}
      bottom={0}
      left={0}
      direction={"column"}
      justifyContent={"center"}
      textAlign={"center"}
      rounded={"8px"}
      border={"1px solid"}
      borderColor={"neutral.100"}
      background={isMobile ? "white" : "none"}
      boxShadow={isMobile ? "" : "none"}
    >
      <Flex direction={"row"} gap={"16px"} alignItems={"center"}>
        <Image
          rounded="full"
          border="1px solid"
          borderColor="neutral.100"
          w="50px"
          h="50px"
          src={
            currentService?.agencyImage && currentService?.agencyImage !== ""
              ? currentService?.agencyImage
              : "/img/logo_smartbeemo.svg"
          }
          objectFit="cover"
          objectPosition="center"
        />
        <Heading as={"p"}>{currentService?.agency}</Heading>
      </Flex>
      <Flex
        pt={isMobile ? "0px" : "4px"}
        direction={"row"}
        align={"center"}
        textAlign={"center"}
        justifyContent={"center"}
        gap={1}
      >
        <Ico.Clock fontSize={"18px"} color={"#69696C"}></Ico.Clock>
        <Text color={"secondary.100"} fontSize={"14px"}>
          Tiempo de respuesta:{" "}
          {currentService?.responseTime !== null
            ? currentService?.responseTime
            : "24 horas"}
        </Text>
      </Flex>
      {isSubmitted ? (
        <Flex
          mt={"16px"}
          justifyContent={"center"}
          alignItems={"center"}
          direction={"column"}
          gap={"8px"}
        >
          <Ico.CircleCheck fontSize={"26px"} color={colors.success[400]} />
          <Text
            fontWeight={"400"}
            fontSize={"14px"}
            color={"secondary.300"}
            fontFamily={"Inter"}
          >
            Hemos recibido tu solicitud
          </Text>
        </Flex>
      ) : (
        <>
          <Text
            mt={isMobile ? 2 : 6}
            fontWeight={"500"}
            fontSize={"14px"}
            color={"neutral.900"}
            textAlign={"left"}
            w={"100%"}
          >
            Cuéntanos de tu proyecto
          </Text>
          <Textarea
            mt={"8px"}
            borderRadius={"8px"}
            border={"1px solid"}
            placeholder="¡Hola! Estoy interesado en sus servicios, me gustaría ponerme en contacto con ustedes"
            value={projectDetails}
            onChange={(e) => setProjectDetails(e.target.value)}
            size="sm"
            bg={"white"}
            borderColor={"#9196A3"}
            resize={"none"}
          />
          <Button
            mt={isMobile ? 4 : 8}
            mb={isMobile ? 0 : 6}
            h={isMobile ? "36px" : "auto"}
            variant={"primary"}
            onClick={handleContactMeClick}
          >
            <Text mr={2} fontWeight={"500"} fontSize={"14px"}>
              Contáctame
            </Text>
            <Ico.PaperPlane fontSize={"18px"}></Ico.PaperPlane>
          </Button>
          <Text
            mt={isMobile ? 3 : 8}
            fontSize={"12px"}
            color={"neutral.500"}
            textAlign={"center"}
          >
            {`Recuerda que ${currentService?.agency} es una agencia que opera independiente de smartBeemo. Al hacer clic en "Contáctame" les enviaremos tu nombre, correo y teléfono para que ellos te contacten directamente.`}
          </Text>
        </>
      )}
    </Flex>
  );
};
