import { Moment } from "moment-timezone";
import moment from "moment-timezone";
import Ipbase from "@everapi/ipbase-js";
import { config } from "@/config";
import "moment-timezone/data/packed/latest.json";
import DateTimeInterface from "./dateTimeInteface";
import { SBErrorPubSub } from "../errors/SBError";

interface GeoLocation {
  country: string;
  nameTranslated: string;
  location: string;
}

class DateTimeMoment implements DateTimeInterface {
  static ipBase = new Ipbase(config.IPBASE_KEY);

  getCurrentTime(): Moment {
    return moment.utc();
  }

  convertLocalTime(time: Moment, timeZone: string): Moment {
    const validTimeZone = moment.tz.zone(timeZone)
      ? timeZone
      : Intl.DateTimeFormat().resolvedOptions().timeZone;

    return time.clone().tz(validTimeZone);
  }

  static async getGeoLocation(): Promise<GeoLocation> {
    const defaultLocation = {
      country: "US",
      nameTranslated: "United States",
      location: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    try {
      const { data } = await DateTimeMoment.ipBase.info();
      return {
        country: data.location.country.alpha2 || defaultLocation.country,
        nameTranslated:
          data.location.country.name_translated ||
          defaultLocation.nameTranslated,
        location: data.location.time_zone || defaultLocation.location,
      };
    } catch (e) {
      // Notificación de error más completa
      SBErrorPubSub.publish({
        component: "DateTimeMoment.getGeoLocation",
        message: `Geolocation fetch failed: ${(e as Error)?.message}`,
        showInProd: false,
      });

      return defaultLocation;
    }
  }

  async getGeoLocation(): Promise<GeoLocation> {
    return DateTimeMoment.getGeoLocation();
  }

  fortmatDate(
    date: Moment,
    dateProps: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    },
    locale = "es"
  ): string {
    try {
      return date.toDate().toLocaleDateString(locale, dateProps);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Date formatting error:", error);
      return date.format("YYYY-MM-DD HH:mm:ss");
    }
  }

  fortmatOnlyDate(
    date: Moment,
    timeZone: string,
    dateProps: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      timeZone: timeZone,
    },
    locale = "es"
  ): string {
    const dateJS = date.toDate();
    const dateFormatted = dateJS.toLocaleDateString(locale, dateProps);
    return dateFormatted;
  }

  fortmatOnlyTime(
    date: Moment,
    timeZone: string,
    dateProps: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: timeZone,
    }
  ): string {
    const dateJS = date.toDate();
    try {
      const dateFormatted = dateJS.toLocaleTimeString("en", dateProps);
      return dateFormatted;
    } catch (error) {
      const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return dateJS.toLocaleTimeString("en", {
        ...dateProps,
        timeZone: browserTz,
      });
    }
  }

  fortmatOnlyDateAndDay(
    date: Moment,
    timeZone: string,
    dateProps: Intl.DateTimeFormatOptions = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour12: true,
      timeZone: timeZone,
    }
  ): string {
    const dateJS = date.toDate();
    const dateFormatted = dateJS.toLocaleDateString("es-ES", dateProps);
    return dateFormatted;
  }

  formatOnlyTimeAmPm(date: Moment, timeZone: string): string {
    const newFormatDate = date.toDate();
    const localTime = moment(newFormatDate).tz(timeZone);
    const hours = localTime.hours();
    const minutes = localTime.minutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedTime = `${formattedHours}:${formattedMinutes} ${ampm}`;
    return formattedTime;
  }
}

export default DateTimeMoment;
