import { BootcampRelease, Faq, PlaylistObject } from "@/schemaTypes";
import {
  Flex,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AccordionCard } from "../Accordion/AccordionCard/accordionCard";
import { FaqsAccordion } from "../Accordion/AccordionFaqs/accordionFaqs";
import { AccordionModules } from "../Accordion/AccordionModules/accordionModules";
import { AccordionFaqs, AccordionModulesData } from "../Accordion/utils";
import { PreviousSessions } from "../PreviousSessions/previousSessions";
import { Schedule } from "../Schedule/schedule";
import {
  BootcampTabsProps,
  createModulesFromClasses,
  ModulesAndSessions,
} from "./utils";

export const BootcampTabs = ({
  bootcamp,
  isRegistered,
  onClickEnroll,
}: BootcampTabsProps) => {
  const { t } = useTranslation();

  const modulesMap = createModulesFromClasses(bootcamp?.release?.classes ?? []);
  const modules: ModulesAndSessions[] = Object.keys(modulesMap).map(
    (moduleName) => ({
      moduleName,
      classes: modulesMap[moduleName],
    })
  );

  const content: AccordionModulesData = {
    data: modules,
    component: (item) => (
      <AccordionCard item={item} isRegistered={isRegistered as boolean} />
    ),
  };
  const faqs: AccordionFaqs = {
    data: bootcamp?.release?.faqs as Faq[],
  };

  return (
    <Tabs variant={"black"} isLazy>
      <TabList sx={{ borderBottom: "none" }}>
        <Tab>{t("Bootcamp schedule")}</Tab>
        {isRegistered && <Tab>{t("Previous sessions")}</Tab>}
        <Tab>{t("frequent questions")}</Tab>
        <TabIndicator />
      </TabList>
      <TabPanels
        display={"flex"}
        flexDir={{ base: "column", lg: "row" }}
        gap={{ base: 6, lg: 12 }}
      >
        <TabPanel flex={{ base: "1", lg: "3" }} h={"max-content"}>
          <Schedule
            release={bootcamp?.release as BootcampRelease}
            bootcampType={bootcamp.parent?.bootcampType ?? ""}
          />
        </TabPanel>
        {isRegistered && (
          <TabPanel flex={{ base: "1", lg: "3" }} h={"max-content"}>
            <PreviousSessions
              playlists={bootcamp.release?.playlists as PlaylistObject}
              linkForResources={bootcamp.release?.linkForResources as string}
            />
          </TabPanel>
        )}
        <TabPanel flex={{ base: "1", lg: "3" }} h={"max-content"}>
          <FaqsAccordion accordionContent={faqs} />
        </TabPanel>
        <Flex flexDir={"column"} gap={4} flex={{ base: "1", lg: "2" }}>
          <AccordionModules
            isRegistered={isRegistered}
            accordionContent={content}
            onClickEnroll={onClickEnroll}
          />
        </Flex>
      </TabPanels>
    </Tabs>
  );
};
