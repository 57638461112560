import { Flex } from "@chakra-ui/react";
import { PageContainer } from "@/components/PageContainer";
import { AppRoute } from "@/AppRoute";
import { ContactMeWidget } from "./ContactMeWidget";
import { ServiceDetailBody } from "./ServiceDetailBody";
import { useGateway } from "../gatewayLayer/useGateway";
import { BigSpinner } from "@/components/BigSpinner";
import { usePresenter } from "../presenter/usePresenter";

export const ServicesMarketplaceDetailComponent: React.FC = () => {
  const { loading } = useGateway();
  const { isMobile } = usePresenter();
  return (
    <PageContainer
      goBackTo={AppRoute.ServicesMarketplace}
      textBackTo={"Regresar"}
    >
      {loading ? (
        <BigSpinner></BigSpinner>
      ) : (
        <>
          <Flex
            display="grid"
            gridTemplateColumns={isMobile ? "1fr" : "2fr 1fr"}
            gap={10}
          >
            <ServiceDetailBody />
            <ContactMeWidget />
          </Flex>
        </>
      )}
    </PageContainer>
  );
};
