import { useEffect, useState } from "react";
import { DataLayerContext, DataLayerValue } from "./context";
import { Category, MarketplaceService } from "@/schemaTypes";
import { useGateway } from "../gatewayLayer/useGateway";
import { searchByTerm } from "../utils/search";
import { filterByCategory } from "../utils/filter";
interface DataLayerProps {
  children: React.ReactNode;
}
const DataLayerProvider = ({ children }: DataLayerProps): JSX.Element => {
  const [servicesForPresenter, setServicesForPresenter] = useState<
    Array<MarketplaceService | null>
  >([]);
  const [currentServiceForPresenter, setCurrentServicesForPresenter] = useState<
    MarketplaceService | null | undefined
  >(undefined);
  const [filteredServicesForPresenter, setFilteredServicesForPresenter] =
    useState<Array<MarketplaceService | null> | null | undefined>(undefined);
  const [searchServicesForPresenter, setSearchServicesForPresenter] = useState<
    Array<MarketplaceService | null> | null | undefined
  >(undefined);
  const [categoriesForPresenter, setCategoriesForPresenter] = useState<
    Array<Category>
  >([]);
  const [subCategoriesForPresenter, setSubCategoriesForPresenter] = useState<
    Array<Category>
  >([]);

  const { allServices, currentService } = useGateway();

  useEffect(() => {
    if (allServices && allServices.length > 0) {
      const categories = allServices.flatMap((service) => {
        return service?.category as Category;
      });
      const uniqueCategories = categories.filter(
        (item, index, self) =>
          index === self.findIndex((cat) => cat.id === item.id)
      );

      const subcategories = allServices.flatMap((service) => {
        return (service?.subcategory || []) as Category[];
      });
      const uniqueSubcategories = subcategories.filter(
        (item, index, self) =>
          index === self.findIndex((sub) => sub.id === item.id)
      );

      uniqueCategories.unshift({ id: "ALL", name: "Todas" } as Category);
      uniqueSubcategories.unshift({ id: "ALL", name: "Todas" } as Category);

      setServicesForPresenter(allServices);
      setCategoriesForPresenter(uniqueCategories);
      setSubCategoriesForPresenter(uniqueSubcategories);
    }
  }, [allServices]);

  useEffect(() => {
    if (currentService) setCurrentServicesForPresenter(currentService);
  }, [currentService]);

  const filterData = (
    selectedMainCategory: string,
    selectedSubcategories: string[]
  ) => {
    const allSubCategoriesSelected =
      selectedSubcategories.length === 1 && selectedSubcategories[0] === "ALL";

    let rawData = searchServicesForPresenter ?? allServices;

    if (selectedMainCategory === "ALL" && allSubCategoriesSelected) {
      if (searchServicesForPresenter) {
        setFilteredServicesForPresenter(rawData);
      } else {
        setFilteredServicesForPresenter(undefined);
      }
    } else {
      if (selectedMainCategory !== "ALL") {
        rawData = filterByCategory(rawData ?? [], selectedMainCategory);
      }

      if (allSubCategoriesSelected) {
        rawData = rawData?.filter((item) => {
          const subcategoryIds = item?.subcategoryId || [];
          return subCategoriesForPresenter.some((subCategory) =>
            subcategoryIds.includes(subCategory.id)
          );
        });
      } else {
        rawData = rawData?.filter((item) => {
          const subcategoryIds = item?.subcategoryId || [];
          return selectedSubcategories.some((subId) =>
            subcategoryIds.includes(subId)
          );
        });
      }

      setFilteredServicesForPresenter(rawData);
    }
  };
  const searchServicesCallback = (searchTerm: string | null) => {
    if (!searchTerm) {
      setSearchServicesForPresenter(undefined);
    } else {
      setSearchServicesForPresenter(
        searchByTerm(servicesForPresenter, searchTerm)
      );
    }
  };

  const value: DataLayerValue = {
    servicesForPresenter,
    searchServicesForPresenter,
    filteredServicesForPresenter,
    categoriesForPresenter,
    subCategoriesForPresenter,
    currentServiceForPresenter,
    filterData,
    searchServicesCallback,
  };
  return (
    <DataLayerContext.Provider value={value}>
      {children}
    </DataLayerContext.Provider>
  );
};
export { DataLayerProvider };
