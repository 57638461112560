import { FlexProps } from "@chakra-ui/layout";
import React from "react";
import { ServiceMarketplaceCardFragment } from "../gatewayLayer/graphql/serviceMarketplaceFragment.generated";
import { Badge, Button, Flex, Heading, Text, Image } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { Card } from "@/components/Card";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";

interface ServiceMarketplaceCardProps {
  service: ServiceMarketplaceCardFragment;
  onClick?: (
    program: ServiceMarketplaceCardFragment,
    e: React.MouseEvent
  ) => void;
  onEditClick?: (
    program: ServiceMarketplaceCardFragment,
    e: React.MouseEvent
  ) => void;
}

export const ServiceCard: React.FC<
  ServiceMarketplaceCardProps & Omit<FlexProps, "onClick">
> = ({ service }) => {
  const navigate = useNavigate();
  const handleClick = (_e: React.MouseEvent) => {
    navigate(`${AppRoute.ServicesMarketplace}/${service.id}`);
  };

  return (
    <Card
      key={service.id}
      userSelect={"none"}
      onClick={handleClick}
      showDivider={false}
      isNewUi={true}
      w={"100%"}
      maxW={"unset"}
      actionButtons={
        <Button
          display={"block"}
          onClick={handleClick}
          variant={"link"}
          fontSize={"14px"}
        >
          <Trans>Ver más</Trans>
        </Button>
      }
    >
      <Image
        border={"1px solid"}
        borderColor={"neutral.100"}
        rounded={"8px"}
        h={"150px"}
        w={"300px"}
        src={
          service.agencyImage && service.agencyImage !== ""
            ? service.agencyImage
            : "/img/logo_smartbeemo.svg"
        }
      />

      <Flex w={"100%"} flexGrow={1} flexDir={"column"} my={2}>
        <Flex>
          <Heading as="p" fontSize={"14px"} w={"100%"}>
            {service.agency}
          </Heading>
        </Flex>
        <Flex align={"flex-start"} mt={"8px"} wrap="wrap">
          {service.category && (
            <Badge
              key={`category-${service.category.id}`}
              px={"8px"}
              py={"2px"}
              bg={"neutral.50"}
              textTransform={"capitalize"}
              w={"fit-content"}
              color={"neutral.500"}
              borderRadius={"6px"}
              mr={"8px"}
            >
              <Text fontSize={"12px"}>{service.category.name}</Text>
            </Badge>
          )}

          {(service.subcategory || []).map((subcategory) => {
            if (!subcategory || !subcategory.id) {
              return null;
            }
            return (
              <Badge
                key={`subcategory-${subcategory.id}`}
                px={"8px"}
                py={"2px"}
                bg={"neutral.50"}
                textTransform={"capitalize"}
                w={"fit-content"}
                color={"neutral.500"}
                borderRadius={"6px"}
                mr={"8px"}
              >
                <Text fontSize={"12px"}>{subcategory.name}</Text>
              </Badge>
            );
          })}
        </Flex>
      </Flex>
    </Card>
  );
};
