import { createContext } from "react";
import type {
  MarketplaceService,
  SendNotificationMarketplaceServiceInput,
} from "@/schemaTypes";
import { ApolloError } from "@apollo/client";
type GatewayLayerValue = {
  allServices: (MarketplaceService | null)[] | undefined | null;
  currentService: MarketplaceService | null | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  reloadData: () => void;
  sendNotificationToAdmins: (
    input: SendNotificationMarketplaceServiceInput
  ) => Promise<boolean>;
};

interface GatewayLayerState {
  allServices?: Array<MarketplaceService | null> | undefined | null;
  currentService?: MarketplaceService | null | undefined;
  loading?: boolean;
  error?: ApolloError;
  reloadData: () => void;
  sendNotificationToAdmins: (
    input: SendNotificationMarketplaceServiceInput
  ) => Promise<boolean>;
}
const GatewayLayerContext = createContext<GatewayLayerValue | null>(null);

export type { GatewayLayerState, GatewayLayerValue };
export { GatewayLayerContext };
