import { useLazyQuery, useSubscription } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import React, { useEffect, useState } from "react";

import { config } from "@/config";
import { GetUserDocument } from "@/graphql/getUser.generated";
import { useLocalStorage } from "@/hooks/useLocalStorage";
import { refreshToken, signOut } from "@/services/authService";
import { UserContext, UserHook } from "./UserProvider";
import {
  UserDetailsDocument,
  UserDetailsSubscription,
} from "@/graphql/getUserSubscription.generated";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "@/AppRoute";
import { UserRole } from "@/schemaTypes";
import { SBErrorPubSub } from "@/utils/errors/SBError";

const useUser = (): UserHook => {
  const context = React.useContext(UserContext);
  if (context === null) {
    throw new Error(`useUser must be used within a UserContext`);
  }
  return context;
};

interface UserAuth {
  isAuthenticated: boolean;
  isLoading: boolean;
}

const useUserAuth = (): UserAuth => {
  const navigate = useNavigate();
  const { setUser, user } = useUser();
  const [isCheckingSession, setIsCheckingSession] = useState(true);
  const [userToken, setUserToken] = useLocalStorage(config.JWT_KEY, null);
  const [storedRefreshToken, setStoredRefreshToken] = useLocalStorage(
    config.RT_KEY,
    null
  );
  const [requestUserData, { data, loading: isLoadingUser, client }] =
    useLazyQuery(GetUserDocument);
  const [loadingUser, setLoadingUser] = useState(true);

  const handleSignOut = async (reason?: string): Promise<void> => {
    await signOut();
    setUser(null);
    setUserToken(null);
    setStoredRefreshToken(null);
    if (!client) return;
    await client.resetStore();
    client.stop();

    if (reason) {
      localStorage.setItem("signout_reason", reason);
    } else {
      localStorage.removeItem("signout_reason");
    }

    navigate(AppRoute.Base, { replace: true });
  };

  useSubscription<UserDetailsSubscription>(UserDetailsDocument, {
    skip: !user?.id,
    variables: { userId: user?.id },
    onData: ({ data: dataPub }) => {
      if (dataPub.data?.me) {
        if (
          dataPub.data.me.roles?.length === 1 &&
          dataPub.data.me.roles[0] === UserRole.Student
        ) {
          handleSignOut("inactive_subscription");
          return;
        }

        const currentUserStr = JSON.stringify(user);
        const newUserStr = JSON.stringify(dataPub.data.me);
        if (currentUserStr !== newUserStr) {
          setUser(dataPub.data.me);
        }
      }
    },
    onError: (error) => {
      SBErrorPubSub.publish({
        component: "useUser.ts",
        message: error?.message || "Error in user subscription",
        showInProd: true,
      });
    },
  });

  useEffect(() => {
    if (!isLoadingUser) {
      setTimeout(() => {
        setLoadingUser(false);
      }, 400);
    }
  }, [isLoadingUser]);

  useEffect(() => {
    if (data) {
      if (data.me) {
        if (
          data.me.roles?.length === 1 &&
          data.me.roles[0] === UserRole.Student
        ) {
          handleSignOut("inactive_subscription");
          return;
        }
        setUser(data.me);

        if (config.VITE_APP_ENV === "production") {
          datadogRum.startSessionReplayRecording();
        }
      } else {
        handleSignOut();
      }
      setIsCheckingSession(false);
    }
  }, [data?.me]);

  useEffect(() => {
    if (userToken) {
      requestUserData();
    } else if (storedRefreshToken) {
      refreshToken(true)
        .then(() => requestUserData())
        .catch(() => {
          setIsCheckingSession(false);
        });
    } else {
      setIsCheckingSession(false);
    }
  }, [userToken, storedRefreshToken]);

  return {
    isAuthenticated: !!user,
    isLoading: isCheckingSession || isLoadingUser || loadingUser,
  };
};

export { useUser, useUserAuth };
