import { useToast } from "@chakra-ui/react";

export const useNotificationToast = () => {
  const toast = useToast();

  const showErrorToast = (title: string, description: string) => {
    toast({
      title,
      description,
      status: "error",
      duration: 4000,
      isClosable: true,
    });
  };

  const showSuccessToast = (title: string, description: string) => {
    toast({
      title,
      description,
      status: "success",
      duration: 4000,
      isClosable: true,
    });
  };

  const showWarningToast = (title: string, description: string) => {
    toast({
      title,
      description,
      status: "warning",
      duration: 4000,
      isClosable: true,
    });
  };

  return {
    showErrorToast,
    showSuccessToast,
    showWarningToast,
  };
};
