import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { Button } from "@/components/Button";
import { colors } from "@/components/Theme/colors";
import { GetActiveMentorshipForUserDocument } from "@/pages/HighTicketMentorship/gatewayLayer/graphql/getActiveMentorshipForUser.generated";
import { useUser } from "@/providers/useUser";
import { EntityName, HighTicketMentorship } from "@/schemaTypes";
import { noCacheHeaders } from "@/utils/headers";
import { useLazyQuery } from "@apollo/client";
import { Box, Flex, Text, Avatar } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface HighTicketWidgetProps {
  isMenuExpanded?: boolean;
}

export const HighTicketNavigationWidget: React.FC<HighTicketWidgetProps> = ({
  isMenuExpanded,
}: HighTicketWidgetProps) => {
  const { user } = useUser();
  const [activeMentorship, setActiveMentorship] = useState<
    Array<HighTicketMentorship> | null | undefined
  >();
  const [getActiveMentorship] = useLazyQuery(
    GetActiveMentorshipForUserDocument,
    {
      variables: {
        userId: user?.id ?? "",
      },
      onCompleted: (data) => {
        setActiveMentorship(
          data.getActiveMentorshipForUser as Array<HighTicketMentorship>
        );
      },
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );
  const navigate = useNavigate();
  const forceNavigate = (path: string) => {
    const currentBasePath = location.pathname.split("/")[1];
    const targetBasePath = path.split("/")[1];

    if (currentBasePath === targetBasePath) {
      navigate(targetBasePath, { replace: true });
      setTimeout(() => navigate(path), 0);
    } else {
      navigate(path);
    }
  };
  const openMentorship = (id: string) => {
    forceNavigate(`${AppRoute.HighTicketMentorship}/${id}`);
  };
  useEffect(() => {
    getActiveMentorship();
  }, [getActiveMentorship]);
  return (
    <Box>
      {activeMentorship &&
        activeMentorship.length > 0 &&
        activeMentorship.map((mentorship, index) => {
          return (
            <Box
              key={mentorship.id}
              w={"100%"}
              minH={"158px"}
              bg={isMenuExpanded ? "neutral.50" : "transparent"}
              mb={index > 0 ? 6 : 4}
              borderRadius={"6px"}
            >
              {isMenuExpanded && (
                <Flex
                  padding={"12px 12px 16px 12px"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  gap={2}
                  alignSelf={"stretch"}
                  borderRadius={"6px"}
                  background={"neutral.50"}
                >
                  <Box
                    display={"flex"}
                    alignItems={"flex-start"}
                    gap={"3px"}
                    alignSelf={"stretch"}
                  >
                    <Flex flexDirection={"column"}>
                      <Flex gap={2} alignItems={"center"} flexDir={"row"}>
                        <Ico.YourMentor
                          color={
                            mentorship.type === EntityName.VipMentorship
                              ? "#427AFF"
                              : "#00A99E"
                          }
                        ></Ico.YourMentor>
                        <Text
                          fontFamily={"inter"}
                          fontSize={"12px"}
                          fontStyle={"normal"}
                          fontWeight={700}
                          lineHeight={"22.4px"}
                          color={"secondary.300"}
                          textTransform={"uppercase"}
                          letterSpacing={"1px"}
                        >
                          {mentorship.type === EntityName.VipMentorship
                            ? "Mentoría VIP"
                            : "Mentoría Élite"}
                        </Text>
                      </Flex>
                      <Text
                        fontFamily={"inter"}
                        fontSize={"12px"}
                        fontStyle={"normal"}
                        fontWeight={400}
                        lineHeight={"22.4px"}
                        color={"secondary.300"}
                      >
                        {mentorship.title}
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={2}
                    alignSelf={"stretch"}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"flex-start"}
                      gap={2}
                      alignSelf={"stretch"}
                    >
                      <Flex alignItems={"center"} gap={2} alignSelf={"stretch"}>
                        <Box position={"relative"}>
                          <Avatar
                            w={"48px"}
                            h={"48px"}
                            src={mentorship.mentor?.avatar ?? ""}
                          ></Avatar>
                          <Box position={"absolute"} bottom={0} right={0}>
                            <Ico.GeneralCheck
                              fontSize={"16px"}
                            ></Ico.GeneralCheck>
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          flex={"1 0 0"}
                        >
                          <Flex alignItems={"center"} gap={"6px"}>
                            <Text
                              fontSize={"12px"}
                              fontWeight={500}
                              fontFamily={"Inter"}
                              fontStyle={"normal"}
                              lineHeight={"19.2px"}
                              color={"secondary.300"}
                            >
                              {"Mentor"}
                            </Text>
                          </Flex>
                          <Text
                            fontFamily={"Inter"}
                            fontStyle={"normal"}
                            fontSize={"12px"}
                            fontWeight={700}
                            lineHeight={"19.2px"}
                            color={"secondary.300"}
                          >
                            {mentorship.mentor?.name}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                    <Button
                      cursor={"pointer"}
                      fontFamily={"Inter"}
                      variant={"link"}
                      fontSize={"14px"}
                      py={0}
                      onClick={() => openMentorship(mentorship.id)}
                    >
                      Ver tu programa
                    </Button>
                  </Box>
                </Flex>
              )}
              {!isMenuExpanded && (
                <Box
                  cursor={"pointer"}
                  onClick={() => openMentorship(mentorship.id)}
                  bg={"neutral.50"}
                  h={"max-content"}
                  p={"12px 6px"}
                >
                  <Ico.YourMentor
                    color={colors.primary[200]}
                    fontSize={"20px"}
                  ></Ico.YourMentor>
                </Box>
              )}
            </Box>
          );
        })}
    </Box>
  );
};
