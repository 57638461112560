import { usePresenter } from "../presenter/usePresenter";
import {
  Box,
  Grid,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { BigSpinner } from "@/components/BigSpinner";
import { Ico } from "@/assets/icons";

export const ServiceImageGallery: React.FC = () => {
  const { currentServiceForView: currentService, isMobile } = usePresenter();
  const [loadingStates, setLoadingStates] = useState(
    new Array(currentService?.imageGallery?.length).fill(true)
  );
  const [currentIndex, setCurrentIndex] = useState<number | null>(null); // Índice de la imagen seleccionada
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleImageLoad = (index: number) => {
    setLoadingStates((prevLoadingStates) => {
      const updatedLoadingStates = [...prevLoadingStates];
      updatedLoadingStates[index] = false;
      return updatedLoadingStates;
    });
  };

  const openModal = (index: number) => {
    setCurrentIndex(index);
    onOpen();
  };

  const handleNext = () => {
    if (currentService?.imageGallery) {
      setCurrentIndex((prevIndex) =>
        prevIndex === null ||
        prevIndex >= (currentService.imageGallery?.length ?? 0) - 1
          ? 0
          : prevIndex + 1
      );
    }
  };

  const handlePrevious = () => {
    if (currentService?.imageGallery) {
      setCurrentIndex((prevIndex) =>
        prevIndex === null || prevIndex <= 0
          ? (currentService.imageGallery?.length ?? 0) - 1
          : prevIndex - 1
      );
    }
  };

  return (
    currentService && (
      <>
        <Grid
          mt={"18px"}
          templateColumns="repeat(3, 1fr)"
          gap={isMobile ? "7px 8px" : "16px 18px"}
        >
          {currentService.imageGallery &&
            currentService.imageGallery.length > 0 &&
            currentService.imageGallery.map((img, index) => (
              <Box key={index} rounded={6} overflow={"hidden"}>
                {loadingStates[index] && <BigSpinner />}
                <Image
                  onLoad={() => handleImageLoad(index)}
                  src={img ?? ""}
                  cursor="pointer"
                  onClick={() => openModal(index)}
                />
              </Box>
            ))}
        </Grid>

        {currentIndex !== null && currentService?.imageGallery && (
          <Modal isOpen={isOpen} onClose={onClose} isCentered size={"xl"}>
            <ModalOverlay bg="rgba(0, 0, 0, 0.6)" backdropFilter="blur(5px)" />
            <ModalContent bg="transparent" boxShadow="none">
              <ModalCloseButton
                size="lg"
                color="white"
                top={isMobile ? "-50px" : "-30px"}
                right={isMobile ? "10px" : "-20px"}
                _hover={{ bg: "rgba(255, 255, 255, 0.2)" }}
              />
              {isMobile ? (
                <ModalBody
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                >
                  <Image
                    src={currentService.imageGallery[currentIndex] ?? ""}
                    maxH="80vh"
                    borderRadius="md"
                  />
                  <IconButton
                    icon={<Ico.SliderArrowIzq fontSize={"50px"} />}
                    aria-label="Previous image"
                    onClick={handlePrevious}
                    position="absolute"
                    right={"60%"}
                    top="150%"
                    zIndex="1"
                    bg="none"
                    color="white"
                    _active={"none"}
                    _hover={{ color: "secondary.50" }}
                    size="lg"
                  />
                  <IconButton
                    icon={<Ico.SliderArrowDer fontSize={"50px"} />}
                    aria-label="Next image"
                    onClick={handleNext}
                    position="absolute"
                    left={"60%"}
                    top="150%"
                    zIndex="1"
                    bg="none"
                    color="white"
                    _active={"none"}
                    _hover={{ color: "secondary.50" }}
                    size="lg"
                  />
                </ModalBody>
              ) : (
                <ModalBody
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                >
                  <IconButton
                    icon={<Ico.SliderArrowIzq fontSize={"50px"} />}
                    aria-label="Previous image"
                    onClick={handlePrevious}
                    position="absolute"
                    left="-40px"
                    top="50%"
                    transform="translateY(-50%)"
                    zIndex="1"
                    bg="none"
                    color="white"
                    _hover={{ color: "secondary.50" }}
                    size="lg"
                  />
                  <Image
                    src={currentService.imageGallery[currentIndex] ?? ""}
                    maxH="80vh"
                    borderRadius="md"
                  />
                  <IconButton
                    icon={<Ico.SliderArrowDer fontSize={"50px"} />}
                    aria-label="Next image"
                    onClick={handleNext}
                    position="absolute"
                    right="-40px"
                    top="50%"
                    transform="translateY(-50%)"
                    zIndex="1"
                    bg="none"
                    color="white"
                    _hover={{ color: "secondary.50" }}
                    size="lg"
                  />
                </ModalBody>
              )}
            </ModalContent>
          </Modal>
        )}
      </>
    )
  );
};
