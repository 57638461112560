import * as Types from '../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type SendNotificationToAdminsMutationVariables = Types.Exact<{
  sendNotificationMarketplaceServiceInput: Types.SendNotificationMarketplaceServiceInput;
}>;


export type SendNotificationToAdminsMutation = { __typename?: 'Mutation', sendNotificationToAdmins: boolean };


export const SendNotificationToAdminsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SendNotificationToAdmins"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"sendNotificationMarketplaceServiceInput"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"SendNotificationMarketplaceServiceInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"sendNotificationToAdmins"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"sendNotificationMarketplaceServiceInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"sendNotificationMarketplaceServiceInput"}}}]}]}}]} as unknown as DocumentNode<SendNotificationToAdminsMutation, SendNotificationToAdminsMutationVariables>;