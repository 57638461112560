import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Button } from "@/components/Button";
import { useEffect, useState } from "react";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { Trans, useTranslation } from "react-i18next";
import {
  CreateMarketplaceServiceInput,
  UpdateMarketplaceServiceInput,
  UserRole,
} from "@/schemaTypes";
import { noCacheHeaders } from "@/utils/headers";
import { useUser } from "@/providers/useUser";
import AdministrationPanel from "..";
import { PageContainer } from "@/components/PageContainer";
import CreateMarketplaceServiceModal from "./CreateMarketplaceServiceModal";
import { GetServicesForAdminPanelDocument } from "./graphql/getServicesForAdminPanel.generated";
import { UpdateMpServiceDocument } from "./graphql/updateMarketplaceService.generated";
import { SaveMpServiceAsDraftDocument } from "./graphql/createMarketplaceServiceAsDraft.generated";
import { SaveMpServiceAndPublishDocument } from "./graphql/createMarketplaceServiceAndPublish.generated";

const MarketplacePage = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const { hasRoles } = useUser();
  const isAdmin = hasRoles([UserRole.Admin]);

  const [selectedServiceId, setSelectedServiceId] = useState<string>("");
  const [
    isCreateMarketplaceServiceModalOpen,
    setIsCreateMarketplaceServiceModalOpen,
  ] = useState<boolean>(false);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState(false);

  const [adminServicesQuery, { data, loading, error }] = useLazyQuery(
    GetServicesForAdminPanelDocument,
    {
      fetchPolicy: "no-cache",
      context: { headers: noCacheHeaders },
    }
  );

  const handleOperationSuccess = (toastTitle: string) => {
    adminServicesQuery();

    toast({
      title: t(toastTitle),
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };

  const resetCreateServiceModal = () => {
    setIsCreateMarketplaceServiceModalOpen(false);
    setIsConfirmDeleteDialogOpen(false);
    setSelectedServiceId("");
  };

  const [saveMarketplaceServiceAsDraft, { loading: isCreateLoading }] =
    useMutation(SaveMpServiceAsDraftDocument);
  const [
    saveMarketplaceServiceAndPublish,
    { loading: isCreatePublishLoading },
  ] = useMutation(SaveMpServiceAndPublishDocument);
  const handleCreateMarketplaceService = (
    values: CreateMarketplaceServiceInput | UpdateMarketplaceServiceInput
  ) => {
    if (values.draft) {
      saveMarketplaceServiceAsDraft({
        variables: {
          createMarketplaceServiceInput:
            values as CreateMarketplaceServiceInput,
        },
      })
        .then((response) => {
          if (response.data?.saveMpServiceAsDraft)
            return handleOperationSuccess("Servicio creado como borrador");
        })
        .finally(resetCreateServiceModal);
    } else if (values.published) {
      saveMarketplaceServiceAndPublish({
        variables: {
          createMarketplaceServiceInput:
            values as CreateMarketplaceServiceInput,
        },
      })
        .then((response) => {
          if (response.data?.saveMpServiceAndPublish)
            return handleOperationSuccess("Servicio creado y publicado");
        })
        .finally(resetCreateServiceModal);
    }
  };

  const [updateMarketplaceService, { loading: isUpdateLoading }] = useMutation(
    UpdateMpServiceDocument
  );
  const handleUpdateMarketplaceService = (
    values: CreateMarketplaceServiceInput | UpdateMarketplaceServiceInput
  ) => {
    updateMarketplaceService({
      variables: {
        updateMarketplaceServiceInput: {
          ...values,
          id: selectedServiceId,
        },
      },
    })
      .then((response) => {
        if (response.data?.updateMpService)
          return handleOperationSuccess("Servicio actualizado");
      })
      .finally(resetCreateServiceModal);
  };

  const handleDeleteService = () => {};

  useEffect(() => {
    adminServicesQuery();
  }, []);

  return (
    <PageContainer title={"Marketplace Servicios"}>
      <AdministrationPanel />
      {loading && <p>Loading...</p>}

      {error && <p>Error: {error.message}</p>}

      {isConfirmDeleteDialogOpen && (
        <ConfirmDialog
          onCancel={resetCreateServiceModal}
          onConfirm={handleDeleteService}
          title={t("Eliminar servicio")}
          message="¿Estas seguro de que quieres eliminar este servicio? Esta acción no se puede revertir. Ten en cuenta que solo podrás eliminar una categoría si no está siendo usada"
        />
      )}

      {data && (
        <Accordion w={"100%"} defaultIndex={[0]} allowMultiple>
          <AccordionItem bg={"white"} rounded={8} borderTop={"none"}>
            <AccordionButton>
              <Text as="span" fontSize={"1.8rem"} variant={"bold"}>
                <Trans>Servicios</Trans>
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
              pb={4}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <TableContainer w={"100%"}>
                <Flex
                  w={"100%"}
                  my={5}
                  px={4}
                  flexDir={"row"}
                  justify={"flex-start"}
                >
                  <Button
                    onClick={() => setIsCreateMarketplaceServiceModalOpen(true)}
                    title="Crear servicio"
                    variant="primary"
                  />
                </Flex>
                <Table variant={"simple"}>
                  <Thead>
                    <Tr>
                      <Th>
                        <Trans>Titulo del servicio</Trans>
                      </Th>
                      <Th>
                        <Trans>Agencia</Trans>
                      </Th>
                      <Th>
                        <Trans>Categoria</Trans>
                      </Th>
                      <Th>
                        <Trans>Subcategoria</Trans>
                      </Th>
                      <Th>
                        <Trans>Publicado</Trans>
                      </Th>
                      <Th textAlign={"center"}>
                        <Trans>Actions</Trans>
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.getServicesForAdminPanel &&
                    data.getServicesForAdminPanel.length > 0 ? (
                      data.getServicesForAdminPanel.map((service) => {
                        return (
                          <Tr key={service?.id} fontSize={"14px"}>
                            <Td isTruncated maxW="200px">
                              {service?.title}
                            </Td>
                            <Td>{service?.agency ?? "Sin agencia"}</Td>
                            <Td>
                              {service?.category?.name ?? "Sin categoria"}
                            </Td>
                            <Td>
                              {(service?.subcategory || [])
                                .map((subcategory) => subcategory?.name)
                                .filter(Boolean)
                                .join(", ") || "Sin subcategoria"}
                            </Td>
                            <Td>
                              <Switch
                                isDisabled
                                isChecked={service?.published ?? false}
                              ></Switch>
                            </Td>
                            <Td
                              display={"flex"}
                              gap={4}
                              justifyContent={"center"}
                            >
                              <Button
                                fontSize={"14px"}
                                title="Edit"
                                variant="secondary"
                                px={2}
                                onClick={() => {
                                  setSelectedServiceId(service?.id ?? "");
                                  setIsCreateMarketplaceServiceModalOpen(true);
                                }}
                              />
                              {isAdmin && (
                                <Button
                                  fontSize={"14px"}
                                  title="Delete"
                                  variant="primary"
                                  px={2}
                                  onClick={() => {
                                    setSelectedServiceId(service?.id ?? "");
                                    setIsConfirmDeleteDialogOpen(true);
                                  }}
                                />
                              )}
                            </Td>
                          </Tr>
                        );
                      })
                    ) : (
                      <Text>No hay servicios creados.</Text>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}

      {isCreateMarketplaceServiceModalOpen && (
        <CreateMarketplaceServiceModal
          isFormSending={
            isCreateLoading || isCreatePublishLoading || isUpdateLoading
          }
          onClose={resetCreateServiceModal}
          onSubmit={
            selectedServiceId
              ? handleUpdateMarketplaceService
              : handleCreateMarketplaceService
          }
          selectedMarketplaceService={data?.getServicesForAdminPanel.find(
            (service) => service?.id === selectedServiceId
          )}
        />
      )}
    </PageContainer>
  );
};

export default MarketplacePage;
